import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function NavbarComponent() {
  return (
    <Navbar
      data-testid="navbar-element"
      collapseOnSelect
      expand="lg"
      fixed="top"
      style={{
        backgroundColor: "white",
        fontFamily: "Montserrat, sans-serif",
      }}
    >
      <Container>
        <Navbar.Brand style={{ color: "#606060" }}>
          <strong>&lt;RJDevs /&gt;</strong>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link href="#home" style={{ color: "#606060" }}>
              home
            </Nav.Link>
            <Nav.Link href="#portfolio" style={{ color: "#606060" }}>
              portfolio
            </Nav.Link>
            <Nav.Link
              href="https://rjforecasts.netlify.app/"
              style={{ color: "#606060" }}
              target="_blank"
            >
              weather
            </Nav.Link>
            <Nav.Link href="#contact" style={{ color: "#606060" }}>
              contact us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
