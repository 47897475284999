import styled from "styled-components";

const SocialsStripStyles = styled.div`
  @media (max-width: 1180px) {
    display: none;
  }

  & svg {
    display: block;
    width: 60px;
    height: 60px;
    color: #606060;
    transition: all 0.2s ease-in-out;
  }

  & svg:hover {
    transform: scale(1.25);
  }

  & svg:first-child {
    margin-top: 110px;
  }
`;

export { SocialsStripStyles };
