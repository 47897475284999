import "./App.css";
import NavbarComponent from "./components/navbar/NavbarComponents";
import SocialMediaBanner from "./sections/socialMediaBanner/SocialMediaBanner";
import About from "./sections/about/About";
import Contact from "./sections/contact/Contact";
import Footer from "./sections/footer/Footer";
import Portfolio from "./sections/portfolio/Portfolio";

function App() {
  return (
    <>
      <hr
        id="home"
        style={{
          backgroundColor: "none",
          borderColor: "white",
        }}
      />
      <NavbarComponent />
      <SocialMediaBanner />
      <hr style={{ margin: "50px auto", width: "70%" }} />
      <About />
      <hr style={{ margin: "50px auto", width: "70%" }} id="portfolio" />
      <Portfolio />
      <hr style={{ margin: "50px auto", width: "70%" }} id="contact" />
      <Contact />
      <hr style={{ margin: "50px auto", width: "70%" }} />
      <Footer />
    </>
  );
}

export default App;
