import styled from "styled-components";

const ContactStyles = styled.div`
  & h1,
  h2 {
    text-align: center;
    color: #606060;
  }

  & h1 {
    font-family: "Handlee", cursive;
  }

  & h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    width: 85%;
    margin: 20px auto;
  }
`;

export { ContactStyles };
