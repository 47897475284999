import { useEffect } from "react";
import { PortfolioGridStyles } from "./PortfolioGridStyles";
import androidApp from "../../images/androidApp.gif";
import anime from "../../images/anime.gif";
import ptApp from "../../images/ptApp.gif";
import contactApp from "../../images/contactApp.gif";
import { Card, Col, Row } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

const PortfolioGrid = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <PortfolioGridStyles>
      <Row xs={1} md={2} className="g-4">
        <Col>
          <Card
            data-aos="fade-right"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              borderColor: "rgb(255, 255, 255, 0.9)",
            }}
          >
            <Card.Img variant="top" src={ptApp} />
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>
                Client Registration App
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            data-aos="fade-left"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              borderColor: "rgb(255, 255, 255, 0.9)",
            }}
          >
            <Card.Img variant="top" src={contactApp} />
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>
                Contact Form App
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            data-aos="fade-right"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              borderColor: "rgb(255, 255, 255, 0.9)",
            }}
          >
            <Card.Img variant="top" src={anime} />
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>
                Anime Fansite
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            data-aos="fade-left"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              borderColor: "rgb(255, 255, 255, 0.9)",
            }}
          >
            <Card.Img
              variant="top"
              src={androidApp}
              style={{ width: "27.6%", margin: "0 auto" }}
            />
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>
                Anime Android App
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </PortfolioGridStyles>
  );
};

export default PortfolioGrid;
