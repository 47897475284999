import styled from "styled-components";

const AboutStyles = styled.div`
  text-align: center;
  width: 75%;
  margin: auto;

  .line {
    display: none;

    @media (max-width: 911px) {
      display: block;
    }
  }

  & h1,
  h2 {
    color: #606060;
  }

  & h1 {
    margin-bottom: 20px;
  }

  & span {
    transition: transform 0.2s;
    display: inline-block;
    position: relative;
    color: inherit;
  }

  & span::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #707070;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  & span:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  & h1 {
    font-family: "Handlee", cursive;
  }

  & h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
  }

  & p {
    color: #707070;
    font-family: "Montserrat", sans-serif;
  }

  & div {
    font-family: "Montserrat", sans-serif;
  }
`;

const USPContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 911px) {
    display: block;
  }
`;

const USPStyles = styled.div`
  color: #707070;
  text-align: start;
  width: 50%;

  .mobile-screen {
    display: none;
  }

  @media (max-width: 911px) {
    margin: 0 auto;
    width: 80%;
    text-align: center;

    .wide-screen {
      display: none;
    }

    .mobile-screen {
      display: block;
      list-style: none;

      li {
        margin-bottom: 15px;
      }

      span {
        font-size: 18px;
      }
    }
  }

  h2 {
    color: #606060;
  }

  li {
    transition: all 0.4s ease-in-out;
  }

  li:hover {
    transform: scale(1.05);
  }

  .wide-screen li::marker {
    content: "🫶🏾 ";
    font-size: 19px;
    font-weight: 600;
  }
`;

const CarouselStyles = styled.div`
  width: 50%;
  margin: auto 0;

  @media (max-width: 911px) {
    width: 100%;
    margin: 0 auto;
  }

  h3 {
    color: #606060;
    font-size: 18px;
  }

  p {
    color: #707070;
  }
`;

export { AboutStyles, USPStyles, CarouselStyles, USPContainer };
