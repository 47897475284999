import styled from "styled-components";

const FormStyles = styled.div`
  width: 85%;
  background: rgb(255, 255, 255, 0.9);
  padding: 20px 40px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: auto;
  font-family: "Montserrat", sans-serif;

  & input,
  textarea {
    width: 100%;
    border: none;
    background: none;
    outline: none;
    font-size: 1.125rem;
    margin-top: 6px;
  }

  & label {
    display: block;
    text-align: left;
    color: #333;
    text-transform: uppercase;
    font-size: 17px;
  }
`;

const InputWrapper = styled.div`
  border: 1px solid #c0c0c0;
  margin: 8px 0;
  padding: 12px 18px;
  border-radius: 8px;
  font-style: normal;

  div {
    /* display: flex; */
  }

  div:focus-within label,
  div:focus-within svg {
    color: #bebebe;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;

  & button {
    color: #606060;
    font-size: 20px;
    background-color: #fff;
    text-decoration: none;
    border: none;
    text-align: center;
    cursor: pointer;
    margin: 0 auto;
    font-weight: 500;

    & span {
      padding-right: 10px;
    }
  }

  & button:hover {
    color: #707070;
  }
`;

export { FormStyles, InputWrapper, ButtonWrapper };
