import { FormStyles, InputWrapper, ButtonWrapper } from "./FormStyles";
import { FaPaperPlane } from "react-icons/fa";
import { AiOutlineUser, AiOutlineMail, AiOutlineMessage } from "react-icons/ai";
import { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { serviceID, publicID, templateID } from "../../secret";
import Toast from "../toast/Toast";
import Aos from "aos";
import "aos/dist/aos.css";

const Form = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  const [showToast, setShowToast] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const form = useRef();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    nameError: "",
    emailError: "",
    msgError: "",
  });

  const validate = () => {
    let nameError = "";
    let emailError = "";
    let msgError = "";

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!emailRegex.test(formData.email)) {
      emailError = "Invalid email format";
    }

    if (!formData.name) {
      nameError = "Name cannot be blank";
    }

    if (!formData.message) {
      msgError = "Message cannot be blank";
    }

    if (emailError || nameError || msgError) {
      setErrors({ ...errors, emailError, nameError, msgError });
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    setShowToast(true);
    setShowForm(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      emailjs
        .sendForm(serviceID, templateID, form.current, publicID)
        .then((result) => {
          console.log(result.text);
        })
        .then(() => {
          setFormData({
            name: "",
            email: "",
            number: "",
            message: "",
          });
        })
        .then(() => {
          handleSubmit();
        });
    }
  };

  const hideToast = () => {
    setShowToast(false);
    setShowForm(true);
  };

  return (
    <>
      {showToast && <Toast hideToast={hideToast} />}
      {showForm && (
        <FormStyles data-aos="fade-up">
          <form ref={form} onSubmit={sendEmail}>
            <InputWrapper>
              <div>
                <label>Name</label>

                <AiOutlineUser style={{ margin: "auto" }} />
                <input
                  name="name"
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                />
              </div>

              {errors.nameError ? (
                <p>
                  <em>{errors.nameError}</em>
                </p>
              ) : null}
            </InputWrapper>

            <InputWrapper>
              <div>
                <label>Email</label>

                <AiOutlineMail style={{ margin: "auto" }} />
                <input
                  name="email"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                />
              </div>
              {errors.emailError ? (
                <p>
                  <em>{errors.emailError}</em>
                </p>
              ) : null}
            </InputWrapper>

            <InputWrapper>
              <div>
                <label>Message</label>
                <AiOutlineMessage style={{ margin: "auto" }} />
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={(e) => {
                    setFormData({ ...formData, message: e.target.value });
                  }}
                ></textarea>
              </div>
              {errors.msgError ? (
                <p>
                  <em>{errors.msgError}</em>
                </p>
              ) : null}
            </InputWrapper>

            <ButtonWrapper>
              <button type="submit">
                <span>Submit</span>
                <FaPaperPlane />
              </button>
            </ButtonWrapper>
          </form>
        </FormStyles>
      )}
    </>
  );
};

export default Form;
