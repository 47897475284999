import { useEffect } from "react";
import { ContactStyles } from "./ContactStyles";
import Form from "../../components/form/Form";
import Aos from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <ContactStyles>
      <div data-aos="zoom-in">
        <h1>Get in Touch</h1>
        <h2>If you would like to know more about our services</h2>
      </div>

      <Form />
    </ContactStyles>
  );
};

export default Contact;
