import React from "react";
import { FooterStyles } from "./FooterStyles";

const Footer = () => {
  return (
    <FooterStyles>
      <p>
        <a href="mailto:rjddevss@gmail.com?&subject=Enquiry from Personal Site&body=Hello RJDevs, I am interested in your services.">
          rjddevss@gmail.com
        </a>
      </p>
      <p>© 2022 RJDevs</p>
    </FooterStyles>
  );
};

export default Footer;
