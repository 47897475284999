import styled from "styled-components";

const SocialMediaBannerStyles = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 80px;
  font-family: "Montserrat", sans-serif;

  & img {
    width: 50%;
    height: 40%;
    border-radius: 5%;
  }
`;

const LinksWrapper = styled.div`
  display: none;
  @media (max-width: 1180px) {
    display: block;
    margin: auto 0;
    text-align: center;
    & h4 {
      color: #606060;
    }
    & div {
      margin-top: 20px;
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 10px;
      color: #707070;

      & svg {
        color: #707070;
        width: 20px;
        height: 20px;
        transition: all 0.2s ease-in-out;
      }

      & svg:hover {
        transform: scale(1.5);
      }

      & p {
        font-size: 120%;
      }
    }
  }
`;

export { SocialMediaBannerStyles, LinksWrapper };
