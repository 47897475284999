import Toast from "react-bootstrap/Toast";

const ToastNotification = ({ hideToast }) => {
  return (
    <Toast onClose={hideToast} style={{ margin: "0 auto" }}>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto" style={{ marginLeft: "auto" }}>
          Yay 🎉
        </strong>
      </Toast.Header>
      <Toast.Body style={{ textAlign: "center" }}>
        We will get back to you soon
      </Toast.Body>
    </Toast>
  );
};

export default ToastNotification;
