import styled from "styled-components";

const SlideStyles = styled.div`
  display: flex;
  justify-content: space-evenly;

  .fade-in {
    animation: fadeIn 1s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export default SlideStyles;
