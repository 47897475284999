import PortfolioGrid from "../../components/portfolioGrid/PortfolioGrid";
import { PortfolioStyles } from "./PortfolioStyles";

const Portfolio = () => {
  return (
    <PortfolioStyles>
      <h1>Our Portfolio</h1>
      <PortfolioGrid />
    </PortfolioStyles>
  );
};

export default Portfolio;
