import { useState } from "react";
import SlideStyles from "./SlideStyles";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
const CarouselComponent = () => {
  const [index, setIndex] = useState(1);
  const [fade, setFade] = useState(false);

  console.log(fade);

  function triggerFade() {
    if (fade === false) {
      setFade(true);
    }
    setTimeout(setFalse, 500);
  }

  const setFalse = () => {
    setFade(false);
  };

  const handleLeftClick = () => {
    if (index === 1) {
      setIndex(3);
      triggerFade();
    } else {
      setIndex(index - 1);
      triggerFade();
    }
  };
  const handleRightClick = () => {
    if (index === 3) {
      setIndex(1);
      triggerFade();
    } else {
      setIndex(index + 1);
      triggerFade();
    }
  };

  if (index === 1) {
    return (
      <SlideStyles>
        <AiOutlineArrowLeft
          style={{ cursor: "pointer" }}
          onClick={handleLeftClick}
        />
        <div className={fade ? "fade-in" : "visible"}>
          <h3>Coming soon</h3>
          {/* <p>
            <em>Coming soon</em>
          </p> */}
        </div>
        <AiOutlineArrowRight
          style={{ cursor: "pointer" }}
          onClick={handleRightClick}
        />
      </SlideStyles>
    );
  } else if (index === 2) {
    return (
      <SlideStyles>
        <AiOutlineArrowLeft
          style={{ cursor: "pointer" }}
          onClick={handleLeftClick}
        />
        <div className={fade ? "fade-in" : "visible"}>
          <h3>Coming soon</h3>
          {/* <p>
            <em>Coming soon</em>
          </p> */}
        </div>
        <AiOutlineArrowRight
          style={{ cursor: "pointer" }}
          onClick={handleRightClick}
        />
      </SlideStyles>
    );
  } else if (index === 3) {
    return (
      <SlideStyles>
        <AiOutlineArrowLeft
          style={{ cursor: "pointer" }}
          onClick={handleLeftClick}
        />
        <div className={fade ? "fade-in" : "visible"}>
          <h3>Coming soon</h3>
          {/* <p>
            <em>Coming soon</em>
          </p> */}
        </div>
        <AiOutlineArrowRight
          style={{ cursor: "pointer" }}
          onClick={handleRightClick}
        />
      </SlideStyles>
    );
  }

  return <div>CarouselComponent</div>;
};

export default CarouselComponent;
