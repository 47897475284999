import styled from "styled-components";

const PortfolioGridStyles = styled.div`
  width: 70%;
  margin: 0 auto;

  & div {
    font-family: "Montserrat", sans-serif;
  }
`;

export { PortfolioGridStyles };
