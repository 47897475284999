import styled from "styled-components";

const PortfolioStyles = styled.div`
  & h1 {
    text-align: center;
    color: #606060;
    margin-bottom: 20px;
    font-family: "Handlee", cursive;
  }
`;

export { PortfolioStyles };
